<template>
  <div id="app">
    <router-view/>
    
  </div>
</template>

<script>

export default {
  name: 'App',
  
  mounted() {
    document.addEventListener('contextmenu', this.disableRightClick);
    localStorage.clear();
  },
  beforeDestroy() {
    document.removeEventListener('contextmenu', this.disableRightClick);
  },
  methods: {
    disableRightClick(event) {
      event.preventDefault();
    }
  }
}
</script>

<style scoped>   
</style>
