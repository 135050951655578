import { post } from "@/util/request";

export const getCategoryList= async ()=>{
    const response = await post("/category/findAllList")
    return response.data.data
}

export const getMobanList= async ()=>{
    const response = await post("/staticMoban/findAllList")
    return response.data.data
}

export const  getRandomSubset=(array, size)=> {
    let shuffled = array.slice(0); // 复制一份数组，以防止修改原数组
    let subset = [];
    let count = shuffled.length;
    
    while (subset.length < size && count > 0) {
      let index = Math.floor(Math.random() * count); // 随机生成索引
      subset.push(shuffled[index]); // 将随机选中的元素加入子集
      shuffled.splice(index, 1); // 从原数组中移除选中的元素
      count--;
    }
    console.log('subset--',subset);
    return subset;
  }
