<template>
  <div class="container">
    <div class="top">
      <div class="back" @click="back">
        <img src="../assets/houtui.png" alt="">
      </div>
      <div class="search">
        <img src="../assets/search2.png" alt="">
        <input v-model="mobanName" type="text" placeholder="请输入关键词">
        <button @click="search">搜索</button>
      </div>
    </div>

    <div class="hot">
      <h3>搜索热词</h3>
      <div class="items">

        <div @click="categoryClick(item.id)" class="item" v-for="item in categoryList" :key="item.id">
          {{ item.name }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getCategoryList } from "@/util/api";
export default {
  components: {

  },
  data() {
    return {
      categoryList: [],
      mobanName:''
    }
  },
  methods: {
    search() {
      this.$router.push({ name: 'jintai', query: { mobanName: this.mobanName } })
    },
    back() {
      this.$router.push('/jintai')
    },
    categoryClick(categoryId) {
      this.$router.push({ name: 'jintai', query: { categoryId: categoryId } })
    }

  },
  async created() {
    this.categoryList = await getCategoryList()
  }
}
</script>
<style lang="less" scoped>
@media screen and (max-width: 768px) {
  * {
    padding: 0px;
    margin: 0px;
    box-sizing: border-box;
  }

  .top {
    width: 100%;
    height: 8vh;
    border-bottom: 1px solid #ECECF0;
    position: relative;
    padding-bottom: 2vh;

    .back {
      width: 30px;
      position: absolute;
      left: 10px;
      top: 2.5vh;

      img {
        width: 25px;
        height: 20px;
      }
    }

    .search {
      width: 90%;
      position: absolute;
      left: 40px;

      img {
        position: absolute;
        top: 3vh;
        left: 15%;
        width: 15px;
        height: 15px;
      }

      input {
        width: 80%;
        height: 40px;
        margin: 0 auto;
        border-radius: 20px;
        display: block;
        margin-top: 10px;
        border: none;
        background: white;
        padding: 10px;
        padding-left: 50px;
    
        font-size: 16px;
        border: 1px solid #007aff;
        outline: none;
      }

      button {
        width: 80px;
        height: 40px;
        background: #007aff;
        border: none;
        position: absolute;
        right: 32px;
        top: 1.2vh;
        color: white;
        border-radius: 20px;
        font-size: 15px;
        font-weight: bold;
      }
    }
  }

  .hot {
    padding: 0px 10px;
    color: #8d8d96;
    letter-spacing: 1px;
    height: 82vh;

    h3 {
      font-size: 15px;
      font-weight: normal;
      margin: 20px 0px;
    }

    .items {
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      width: 100%;
    }

    .items .item {
      flex-basis: 20%;
      text-align: center;
      border: 1px solid #c8c8d0;
      padding: 5px 0px;
      margin-bottom: 15px;
      color: #333443;
      font-size: 12px;
      margin-right: 15px
    }

  }


}
</style>
