import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import router from '@/router/index'
import Cookies from "js-cookie"; 
import download from "@/directives/index"; 
import DevtoolsDetection from './mixins/devtools-detection';
import VueTour from 'vue-tour'
import waterfall from "vue-waterfall2";
require('vue-tour/dist/vue-tour.css')
Vue.use(waterfall);
Vue.use(VueTour)
//Vue.mixin(DevtoolsDetection);打开即可防止开发者模式
Vue.use(waterfall);
// 注册一个全局自定义指令 `v-download`
Vue.directive('download', download);
Vue.config.productionTip = false
Vue.use(ElementUI); 
Vue.use(router)
Vue.prototype.$cookie=Cookies 
new Vue({ 
  router, 
  render: h => h(App), 
}).$mount('#app')
