import axios from 'axios'
import qs from 'qs'
const request = axios.create({
    baseURL: '/api',  // 注意！！ 这里是全局统一加上了 '/api' 前缀，也就是说所有接口都会加上'/api'前缀在，页面里面写接口的时候就不要加 '/api'了，否则会出现2个'/api'，类似 '/api/api/user'这样的报错，切记！！！
    timeout: 50000
}) 

request.interceptors.request.use(function(config){
    //设置post请求的请求头部信息
    if(config.method=='post'){
      config.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8'
      config.data = qs.stringify(config.data);
    }
    return config;
  },function(error){
    return Promise.reject(error);
  });
 

export function get(url,params) {
    //params.t = new Date().getTime(); //get方法加一个时间参数,解决ie下可能缓存问题.
    return request({
        url:url,
        method: 'get',
        headers: {
        },
         params
    })
}


//封装post请求
export function post(url, data) {
    //默认配置
    let sendObject={
        url:url,
        method: 'post',
        headers: {
            // 'Content-Type':'application/json;'
        },
        data:data 
    };
    // sendObject.data=JSON.stringify(data);
    return request(sendObject)
}