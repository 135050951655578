export default {
    bind: function (el, binding) {
        el.addEventListener('click', () => {
            const url = binding.value;  // 完整的url则直接使用
            let urlArray = url.split('/');
            let filename = urlArray[urlArray.length-1];  // 原始文件名称 1.jpg
            
            const a = document.createElement('a');
            // 将url转成blob地址。如果url是一个完整的链接，需要后端开清跨域请求
            fetch(url).then(res => res.blob())
                .then(blob => { // 将链接地址字符内容转变成blob地址
                    a.href = URL.createObjectURL(blob)
                    a.target="_blank"
                    a.download = filename // 下载文件的名字
                    document.body.appendChild(a)
                    a.click() 
                })
        })
    }
}
