<template>
  <div class="carousel-container">
    <div class="carousel" :style="carouselStyle">
      <div class="carousel-item" v-for="(image, index) in visibleImages" :key="index">
        <img :src="image" alt="Image" />
      </div>
    </div>
    <button @click="prev" class="nav-button prev-button">&lt;</button>
    <button @click="next" class="nav-button next-button">&gt;</button>
  </div>
</template>

<script>
export default {
  data() {
    return {
      images: [
        'https://via.placeholder.com/300?text=1',
        'https://via.placeholder.com/300?text=2',
        'https://via.placeholder.com/300?text=3',
        'https://via.placeholder.com/300?text=4',
        'https://via.placeholder.com/300?text=5',
        'https://via.placeholder.com/300?text=6', // 多于5张的图片
      ],
      currentIndex: 0,
      transitioning: false,
    };
  },
  computed: {
    visibleImages() {
      // 在末尾附加前5张图片以实现无缝轮询
      return [...this.images, ...this.images.slice(0, 5)];
    },
    carouselStyle() {
      const offset = this.currentIndex * -(20 + 2); // 每张图片宽度为20%，外加2%的间隔
      return {
        transform: `translateX(${offset}%)`,
        transition: this.transitioning ? 'transform 0.5s ease' : 'none',
      };
    },
  },
  methods: {
    next() {
      if (this.currentIndex < this.images.length) {
        this.currentIndex++;
      } else {
        this.transitioning = false;
        this.currentIndex = 1;
      }
      this.applyTransition();
    },
    prev() {
      if (this.currentIndex > 0) {
        this.currentIndex--;
      } else {
        this.transitioning = false;
        this.currentIndex = this.images.length - 1;
      }
      this.applyTransition();
    },
    applyTransition() {
      this.transitioning = true;
      setTimeout(() => {
        if (this.currentIndex === this.images.length) {
          this.transitioning = false;
          this.currentIndex = 0;
        } else if (this.currentIndex === -1) {
          this.transitioning = false;
          this.currentIndex = this.images.length - 5;
        }
      }, 500);
    },
  },
};
</script>

<style>
.carousel-container {
  position: relative;
  width: 100%; /* 容器宽度100% */
  overflow: hidden;
}

.carousel {
  display: flex;
  transition: transform 0.5s ease;
}

.carousel-item {
  min-width: 20%;
  margin-right: 2%; /* 每张图片右侧间隔2% */
  box-sizing: border-box;
}

.carousel-item img {
  width: 100%;
}

.nav-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  cursor: pointer;
}

.prev-button {
  left: 0;
}

.next-button {
  right: 0;
}
</style>
