<template>
    <div class="container">
        <!-- <v-viewer :options="viewerOptions">
            <div class="content" v-html="content"></div>
        </v-viewer> -->
        <!-- <div class="content" v-html="content" @click="handleImageClick"></div>
        <vue-image-lightbox :images="images" :show="showLightbox" @close="showLightbox = false" /> -->
        <div>
            <div class="content" v-html="content" @click="openShowViewer($event)"></div>
            <el-image-viewer ref="imageViewer" v-if="showViewer" :initial-index="currentIndex" :on-close="closeViewer"
                :url-list="images" />
        </div>
    </div>
</template>

<script>

import { post } from "@/util/request";
import ElImageViewer from 'element-ui/packages/image/src/image-viewer'
import VueImageLightbox from 'vue-image-lightbox';
import Hammer from 'hammerjs';
export default {
    components: {
        VueImageLightbox,
        ElImageViewer
    },
    data() {
        return {
            showViewer: false,
            images: [],
            title: '',
            content: '',
            currentImage: '',

            currentImageIndex: 0,
            scale: 1,
            posX: 0,
            posY: 0,
        }
    },
    methods: {
        onPreview() {
            this.showViewer = true
        },
        // 关闭查看器
        closeViewer() {
            this.showViewer = false
        },
        async getDeatil(id) {
            var params = { 'id': id }
            console.log('params---', params);
            const response = await post("/course/getCourseById", params)
            console.log('response---', response);
            this.title = response.data.data.title
            this.content = response.data.data.content
            this.getImagesFromHTML(this.content)
        },
        handleImageClick(event) {
            if (event.target.tagName === 'IMG') {
                this.openLightbox(event.target.src);
            }
        },
        initHammer() {
            const viewerElement = this.$refs.imageViewer.$el.querySelector('.el-image-viewer__img');
            if (viewerElement) {
                const hammer = new Hammer(viewerElement);
                hammer.get('pinch').set({ enable: true });
                hammer.get('pan').set({ direction: Hammer.DIRECTION_ALL });

                let lastScale = 1;
                let lastPosX = 0;
                let lastPosY = 0;

                hammer.on('pinchmove', (ev) => {
                    this.scale = lastScale * ev.scale;
                    viewerElement.style.transform = `scale(${this.scale}) translate(${this.posX}px, ${this.posY}px)`;
                });

                hammer.on('pinchend', () => {
                    lastScale = this.scale;
                });

                hammer.on('panmove', (ev) => {
                    this.posX = lastPosX + ev.deltaX;
                    this.posY = lastPosY + ev.deltaY;
                    viewerElement.style.transform = `scale(${this.scale}) translate(${this.posX}px, ${this.posY}px)`;
                });

                hammer.on('panend', () => {
                    lastPosX = this.posX;
                    lastPosY = this.posY;
                });
            }
        },
       
        openShowViewer(event) {
            console.log('点击了---', event.target.tagName);
            // 检查点击的是否是图片
            if (event.target.tagName === 'IMG') {
                const src = event.target.src;
                let index = this.images.indexOf(src)

                if (index !== -1) {
                    console.log('index---', index);
                    this.currentIndex = index;
                    this.showViewer = true
                    this.$nextTick(() => {
                        this.initHammer();
                    });
                }
            }
        },
        getImagesFromHTML(htmlString) {

            const imgTags = htmlString.match(/<img\b[^>]*>/gi);
            console.log(imgTags);
            const srcList = imgTags.map(img => {
                const srcMatch = img.match(/src="([^"]*)"/);
                return srcMatch ? srcMatch[1] : '';
            });
            this.images = srcList;

        }

    },
    created() {
        const id = this.$route.query.id;
        this.getDeatil(id)

    },
    mounted() {

    }
}
</script>
<style lang="less" scoped>
* {
    padding: 0px;
    margin: 0px;
}

.container{
 height: 92vh;
}

::v-deep .content {
    padding: 20px
}

::v-deep .content img {
    width: 100%;
    object-fit: cover;
}
</style>
