
import Vue from 'vue'
import VueRouter from 'vue-router'
import index from '@/views/index.vue'
import jintai from '@/views/jintai.vue'
import jintai2 from '@/views/jintai2.vue'
import jintaidetail from '@/views/jintaidetail.vue'
import jiaocheng from '@/views/jiaocheng.vue'
import search from '@/views/search.vue'
import wentiDetail from '@/views/wentiDetail.vue'
import layout from '@/views/layout.vue'
import test from '@/views/test.vue'
Vue.use(VueRouter)
const routes = [
    {
        path: '/', name: 'layout', component: layout,
        children: [
            {
                path: '',
                redirect: "index"
            },
            
            {
                path: 'index',
                name: 'index',
                component: index
            },
            {
                path: 'jintai',
                name: 'jintai',
                component: jintai
            },
            {
                path: 'jintai2',
                name: 'jintai2',
                component: jintai2
            },
            {
                // jintaidetail/:id
                path: 'jintaidetail',
                name: 'jintaidetail',
                component: jintaidetail,
                // props: true 
            },
            
           
           
            {
                path: 'jiaocheng',
                name: 'jiaocheng',
                component: jiaocheng
            },
            {
                path: 'search',
                name: 'search',
                component: search 
            },
            {
                path: 'wentiDetail',
                name: 'wentiDetail',
                component: wentiDetail 
            }
            ,
            {
                path: 'test',
                name: 'test',
                component: test 
            }

        ]
    },



]
const router = new VueRouter({
    routes,
    mode: 'hash'
})
router.beforeEach((to, from, next) => {

    // 路由跳转后，让页面回到顶部
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    window.pageYOffset = 0;
    //next() 放行 next('/login') 强制跳转
    // 调用 next()，一定要调用 next 方法，否则钩子就不会被销毁!!
    next();

});

export default router



